import React, { Component } from 'react';
import "./Policy.scss";

export class PolicyFooter extends Component {
	static displayName = PolicyFooter.name;

	onClickOpenPolicy() {
		window.open('https://www.snowsoftware.com/int/seo/legal-privacy-policy');
	};
	onClickOpenTerms() {
		window.open('https://www.snowsoftware.com/legal/termsofuse');
	}

	render() {
		return (
			<div className="i-Policy_FooterNote">
				<h6 className="i-Text-heading-six i-Policy_FooterNote-text">
					<span className="i-Policy_FooterNote-snow">Copyright© 2023 Snow Software</span>
					<div className="i-Policy_FooterNote-right">
						<span className="i-Policy_FooterNote-term" onClick={() => this.onClickOpenTerms()}>Terms of Use</span>
						<span className="i-Policy_FooterNote-dot">&#183;</span>
						<span className="i-Policy_FooterNote-policy" onClick={() => this.onClickOpenPolicy()}>Privacy policy</span>
					</div>
				</h6>
			</div>
		)
	}
}
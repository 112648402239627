import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { SignInMultiplexer } from './components/signInMultiplexer/SignInMultiplexer';
import { Reset } from "./components/reset/Reset";
import { Error } from "./components/error/Error";
import { Navbar } from './components/navbar/Navbar';
import { RequestReset } from "./components/requestreset/RequestReset";
import { SignIn } from "./components/signin/SignIn";
import { PolicyFooter } from './components/policy/PolicyFooter';
import { SignInDomain } from "./components/signInDomain/SignInDomain";
import { NotFound } from "./components/notfound/NotFound";
import { PageIllustration } from './components/pageIllustration/PageIllustration';
import {EmailFactor} from "./components/emailfactor/EmailFactor";
import {SelectFactor} from "./components/selectfactor/SelectFactor";

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <div className="i-PageContent">
          <div className="i-PageSide-left">
            <div className="i-PageSide-leftContent">
              <Navbar />
              <Routes>
                <Route path='/app/signinmultiplexer' element={<SignInMultiplexer />} />
                <Route path='/app/reset' element={<Reset />} />
                <Route path='/app/requestreset' element={<RequestReset />} />
                <Route path='/app/error' element={<Error />} />
                <Route path='/app/signin' element={<SignIn />} />
                <Route path='/app/signindomain' element={<SignInDomain />} />
                <Route path='/app/factors/select' element={<SelectFactor />} />
                <Route path='/app/factors/email' element={<EmailFactor />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
            <div className="i-PageSide-leftFooter">
              <PolicyFooter />
            </div>
          </div>
          <PageIllustration />

        </div>

      </Layout>
    );
  }
}

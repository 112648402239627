import * as cookies from "js-cookie";

async function post(path, pay) {
    let token = cookies.get("id.csrf");

    return await fetch(path, {
        method: 'POST',
        body: JSON.stringify(pay),
        headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': token
        },
        credentials: 'include'
    });
}

export async function signInExternal(scheme, regionHostname, returnUrl, email, slug) {
    window.location.href = `${regionHostname}/idp/api/account/signinexternal?returnUrl=${encodeURIComponent(returnUrl)}&scheme=${scheme}&email=${encodeURIComponent(email)}&slug=${encodeURIComponent(slug)}`;
}

export async function signInMultiplexer(email, slug) {
    return await post('/idp/api/account/signinmultiplexer', {email: email.trim(), slug: slug});
}

export async function signIn(email, slug, password, regionHostname, returnUrl) {
    return await post(`${regionHostname}/idp/api/account/signin`, {email: email.trim(), slug: slug, password: password, returnUrl: returnUrl});
}

export async function reset(email, password, confirmPassword, code) {
    return await post('/idp/api/account/reset', {email: email.trim(), password: password, confirmPassword: confirmPassword, code: code});
}

export async function requestReset(email, slug, regionHostname) {
    return await post(`${regionHostname}/idp/api/account/requestreset`, {email: email.trim(), slug: slug.trim()});
}

export async function requestEmailMfaCode() {
    return await post('/idp/api/account/mfa/factors/email/request');
}

export async function submitEmailMfaCode(code, returnUrl) {
    return await post('/idp/api/account/mfa/factors/email/confirm', {code: code.trim(), returnUrl: returnUrl});
}

export function windowRedirect(regionHostname, returnUrl) {
    window.location.href = `${regionHostname}${returnUrl}`;
}
